import axios from '@axios'

export default function (route = '') {
  const PREFIX = `parcelamentos/${route}`

  return {
    getObservacoes(id) {
      return axios.get(`${PREFIX}/parcela/${id}/observacao`)
    },

    salvarObservacao(observacao) {
      return axios.post(`${PREFIX}/parcela/observacao/salvar`, observacao)
    },

    removerObservacao(id) {
      return axios.delete(`${PREFIX}/observacao/${id}/remover`)
    },
  }
}
